import { Navigate, useOutlet, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";


export const ProtectedLayout = ({
    roles,
    permissions,
    redirectPath = '/landing',
    children,
}) => {
    const { user, allowed } = useAuth();
    const outlet = useOutlet();
    console.log('pass protected layout');
    console.log(roles);
    console.log(permissions);
    console.log(user);
    console.log(allowed);

    if (!allowed.status) {
        return <Navigate to={redirectPath} replace />;
    }

    if (!user.users) {
        return <Navigate to={redirectPath} replace />;
    }

    if (user.users.roles !== null) {
        console.log('pass roles', user.users.roles);
        console.log('pass roles', roles);
        console.log(user.users.roles.includes(roles));
        if (!user.users.roles.includes(roles)) {
            return <Navigate to={redirectPath} replace />;
        }
    }
    if (user.users.permissions !== null) {
        console.log('pass roles', user.users.permissions);
        if (!user.users.permissions.includes(permissions)) {
            return <Navigate to={redirectPath} replace />;
        }
    }



    return children ? children : <Outlet />;
};