import React, { Suspense, lazy, useState, useEffect } from "react";
//import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from "react-router-dom";

import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
  Router, Routes, Navigate, Outlet
} from "react-router-dom";
//"react-router-dom": "^6.16.0",

import asyncPromiseRequest from './utils/Requests'
import TopMenu from "./views/components/TopMenu";


//https://blog.logrocket.com/authentication-react-router-v6/#using-react-router-v64-data-library-apis
//https://codesandbox.io/p/sandbox/react-router-v6-auth-demo-updated-t28l48?file=%2Fsrc%2FApp.js%3A11%2C1-11%2C64
//https://www.robinwieruch.de/react-router-private-routes/
import { AuthLayout } from "./components/AuthLayout";
import { ProtectedLayout } from "./components/ProtectedLayout";
/************************** NOTES ********************************** */

import './App.scss';
//import 'bootstrap/dist/css/bootstrap.min.css';

//import "bootstrap/scss/bootstrap.scss";
//import "bootstrap/scss/bootstrap.scss";
//import "bootstrap/dist/js/bootstrap.bundle.js";
//import "./scss/variables.scss";




import Moment from 'react-moment';
import moment from 'moment/min/moment-with-locales';
//import 'moment-timezone'; // installé mais jamais utilisé
/* ISSUE FIXED */
/* Can't resolve './locale' in 'C:\wamp64\www\react_bootstrap_shop\E-Commerce-Template\node_modules\moment\min */
/* changer ./locale/ par ./../locale/ dans le fichier moment/min/moment-with-locales */


/* ISSUE FIXED */
/* Cannot read properties of null (reading 'removeEventListener') with react-modern-calendar-datepicker
This is an issue of the library with React v17. A contributor has created a fork to fix this. You could probably use that library for it.
https://www.npmjs.com/package/@hassanmojab/react-modern-calendar-datepicker
Github Thread: https://github.com/hassanmojab/react-modern-calendar-datepicker
*/

/* ISSUE FIXED */
/*
react-data-table-component / styled-components
https://stackoverflow.com/questions/76887518/react-data-table-components-error-warning-react-does-not-recognize-the-sortact
*/

// Sets the moment instance to use.
Moment.globalMoment = moment;

// Set the locale for every react-moment instance to French.
Moment.globalLocale = 'fr';

// Set the output format for every react-moment instance.
Moment.globalFormat = 'DD MMMM YYYY';

/* // Set the timezone for every instance.
Moment.globalTimezone = 'Europe/Paris';

// Set the output timezone for local for every instance.
Moment.globalLocal = true;

// Use a <span> tag for every react-moment instance.
Moment.globalElement = 'span'; */





const HomeView = lazy(() => import("./views/home/home"));
const SignView = lazy(() => import("./views/sign/sign"));
const FormView = lazy(() => import("./views/form/form"));

const ReservationView = lazy(() => import("./views/reservation/reservation"));
const ReservationCustomView = lazy(() => import("./views/reservation/reservationCustom"));
const GalerieView = lazy(() => import("./views/galerie/galerie"));
const CarteView = lazy(() => import("./views/carte/carte"));
const ContactView = lazy(() => import("./views/contact/contact"));

const AdminHomeView = lazy(() => import("./views/admin/adminHome"));
const AdminReservationView = lazy(() => import("./views/admin/adminReservation"));
const AdminUploadFactureView = lazy(() => import("./views/admin/adminUploadFacture"));
const AdminFactureUsersView = lazy(() => import("./views/admin/adminFactureUsers"));


const SatisfactionView = lazy(() => import("./views/satisfaction/satisfaction"));
const CguSatisfactionView = lazy(() => import("./views/satisfaction/cgu_satisfaction"));
const ConfidentialiteView = lazy(() => import("./views/satisfaction/confidentialite"));

const EvenementView = lazy(() => import("./views/evenement/evenement"));




let userTemp = {};
if (localStorage.getItem("user") === null) {
  userTemp = JSON.stringify(userTemp);
  localStorage.setItem("user", userTemp);
}



const getIsAllowed = () =>
  new Promise((resolve, reject) => {
    let data1 = { url: 'auth/logged1', params: {}, method: 'GET', base: 'API' }
    asyncPromiseRequest(data1).then((response) => {
      console.log(response)
      if (response) {
        let data = response.data;
        resolve(data);
      } else {
        reject("Error");
      }
    })

  });

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        element={<AuthLayout />}
        loader={() => defer({ isAllowed: getIsAllowed() })}
      >

        <Route path="/admin" element={<AdminHomeView />} />
        <Route path="/admin/reservation" element={<AdminReservationView />} />
        <Route path="/admin/upload_facture" element={<ProtectedLayout redirectPath="/login" roles="admin" permissions="all"><AdminUploadFactureView /></ProtectedLayout>} />
        <Route path="/admin/factures" element={<ProtectedLayout redirectPath="/login" roles="admin" permissions=""><AdminFactureUsersView /></ProtectedLayout>} />


        <Route exact path="/" element={<HomeView animate={true} />} />
        <Route exact path="/login" element={<SignView />} />
        <Route exact path="/form" element={<FormView />} />

        <Route exact path="/reservation" element={<ReservationView />} />
        <Route exact path="/reservation/:id" element={<ReservationCustomView />} />
        <Route exact path="/galerie" element={<GalerieView />} />
        <Route exact path="/carte" element={<CarteView />} />
        <Route exact path="/contact" element={<ContactView />} />
        <Route exact path="/satisfaction/:id/:note" element={<SatisfactionView />} />
        <Route exact path="/cgu_satisfaction" element={<CguSatisfactionView />} />
        <Route exact path="/gestion-donnees-et-droits" element={<ConfidentialiteView />} />

        <Route exact path="/evenement" element={<EvenementView />} />
        <Route element={HomeView} />
      </Route >

    </>
  )
);

function App(props) {

  let usertest = {
    id: '1',
    name: 'robin',
    permissions: ['analyze'],
    roles: ['admin'],
  }

  const [user, setUser] = React.useState(null);
  const [isAuth, setIsAuth] = React.useState(false);

  const handleLogin = () =>
    setUser({
      id: '1',
      name: 'robin',
      permissions: ['analyze'],
      roles: ['admin'],
    });
  const handleLogout = () => setUser(null);





  useEffect(() => {

    let userTemp = {};
    if (localStorage.getItem("user") === null) {
      userTemp = JSON.stringify(userTemp);
      localStorage.setItem("user", userTemp);
    } else {
      userTemp = JSON.parse(localStorage.getItem("user"));
      setUser(usertest)
      setIsAuth(true)
    }
    console.log(userTemp);
    /*     if (Object.keys(userTemp).length > 0) {
          let isAllowedTemp = isAllowed().then((response) => {
            console.log(response);
            if (response === true) {
              setUser(userTemp.user)
              setIsAuth(true)
            } else {
              console.log('pas connecté');
              setUser(null)
              setIsAuth(true)
              userTemp = JSON.stringify({});
              localStorage.setItem("user", userTemp);
            };
    
          });
        }; */
  }, [])

  useEffect(() => {
    console.log(user);

  }, [user])




  return (
    <Router /* forceRefresh={true} */>
      <React.Fragment>
        {/* <Header globalCart={globalCart} /> */}
        {/* <TopMenu globalCart={globalCart} setGlobalCart={setGlobalCart} globalUser={globalUser} setGlobalUser={setGlobalUser} isSignOpen={isSignOpen} setIsSignOpen={setIsSignOpen} /> */}
        {/* <Notification /> */}

        <Suspense
          fallback={
            <div className="text-white text-center mt-3">Chargement...</div>
          }
        >

          <Routes>
            <Route exact path="/" element={<HomeView animate={true} />} />
            <Route exact path="/sign" element={<SignView />} />
            <Route exact path="/form" element={<FormView />} />

            <Route exact path="/reservation" element={<ReservationView />} />
            <Route exact path="/galerie" element={<GalerieView />} />
            <Route exact path="/carte" element={<CarteView />} />
            <Route exact path="/contact" element={<ContactView />} />



            {/*  <Route
              path="admin"
              element={
                <ProtectedRoute
                  redirectPath="/sign"
                  isAllowed={!!user && user.roles.includes('admin')}
                >
                  <AdminHomeView />
                </ProtectedRoute>
              }
            />



            <Route element={<ProtectedRoute isAllowed={!!user} />}>
              <Route path="/admin/reservation" element={<AdminReservationView />} />
              <Route path="/admin/upload_facture" element={<AdminUploadFactureView />} />
            </Route> */}

            {/* <Route exact path="/admin/upload_facture" element={
              <ProtectedRoute user={user}>
                <AdminUploadFactureView />
              </ProtectedRoute>
            } /> */}

            <Route exact path="/sign" element={<SignView />} />




            <Route exact path="/satisfaction/:id/:note" element={<SatisfactionView />} />
            <Route exact path="/cgu_satisfaction" element={<CguSatisfactionView />} />
            <Route exact path="/gestion-donnees-et-droits" element={<ConfidentialiteView />} />

            <Route exact path="/evenement" element={<EvenementView />} />





            <Route element={HomeView} />
          </Routes>

        </Suspense>
      </React.Fragment>
    </Router>
  );
}

export default App;
