import { createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import asyncPromiseRequest from '../utils/Requests'
const AuthContext = createContext();

export const AuthProvider = ({ children, userAllowedData }) => {
    console.log(userAllowedData);
    const [user, setUser] = useLocalStorage("user");
    const [allowed, setAllowed] = useState(userAllowedData)
    const navigate = useNavigate();



    // call this function when you want to authenticate the user
    const login = async (value) => {

        let data3 = { url: 'users/user_exist', params: { username: 'phil.arnaud.goddet@gmail.fr' }, method: 'POST', base: 'API' };
        let data = { url: 'custom_service', params: {}, method: 'GET', base: 'API' }
        let data1 = { url: 'auth/logged', params: {}, method: 'GET', base: 'API' }
        let data2 = { url: 'auth/login', params: value, method: 'POST', base: 'API' }

        return asyncPromiseRequest(data2).then((response) => {
            console.log(response);
            if (response.data.user !== 'null') {
                let user = JSON.stringify(response.data);
                localStorage.setItem("user", user);
                setUser(response.data);
                setAllowed({ status: true });
                navigate("/admin");
            } else {
                return response;
            }
        });
    };

    // call this function to sign out logged in user
    const logout = () => {
        setUser(null);
        navigate("/", { replace: true });
    };
    const value = {
        user,
        allowed,
        login,
        logout,
    };

    /*     const value = useMemo(
            () => ({
                user,
                allowed,
                login,
                logout,
            }),
            [user]
        ); */
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};