import { Suspense } from "react";
import { useLoaderData, useOutlet, Await } from "react-router-dom";

import { AuthProvider } from "../hooks/useAuth";

export const AuthLayout = () => {
    const outlet = useOutlet();

    const { isAllowed } = useLoaderData();
    return (
        <Suspense fallback={<div className="text-white text-center mt-3">Chargement...</div>}>
            <Await
                resolve={isAllowed}
                errorElement={<p>Something went wrong!</p>}
                children={(data) => (
                    <AuthProvider userAllowedData={data}>{outlet}</AuthProvider>
                )}
            />
        </Suspense>
    );
};